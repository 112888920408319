import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import BlogOverview from "../../../components/blog/overview/blogOverview.de.mdx";
import Layout from "../../../components/blog/layout.de";
import AppImage from "../../../components/blog/appimage/appImage";
export const query = graphql`
  {
    mdx(slug: { eq: "kalorienzaehler-app-exportieren-kostenlos/index.de" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_introduction {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_introduction_alt
        image_introduction_desc
        image_introduction_credit_text
        image_introduction_credit_link
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        date(formatString: "D. MMMM YYYY", locale: "de-DE")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const Link = makeShortcode("Link");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Du trackst deine Mahlzeiten und dein Körpergewicht, hast mehrere
  Rezepte erstellt und deine Lebensmittel-Favoriten mit Portionsgrößen versehen? Das sind wertvolle und mühselig erstellte Daten,
  deren Verlust für die meisten von uns sehr schmerzhaft wäre. ShapeGanic bietet dir jetzt die Möglichkeit deine Daten manuell zu sichern.
  Hierfür verwendet ShapeGanic ein offenes Dateiformat und du kannst über deine Backups frei verfügen.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_introduction)} imageAlt={props.data.mdx.frontmatter.image_introduction_alt} imageDesc={props.data.mdx.frontmatter.image_introduction_desc} imageAuthor={props.data.mdx.frontmatter.image_introduction_credit_text} imageLink={props.data.mdx.frontmatter.image_introduction_credit_link} mdxType="Image" />
    <h2>{`Kalorienzähler ohne Anmeldung: Datenschutz neu definiert`}</h2>
    <p>
  Immer wieder berichten die Medien über erfolgreiche Hacker-Angriffe auf Server von App-Unternehmen.
  Auch ein populäres konkurrierendes{" "}
  <Link url={props.pageContext.frontmatter.myfitnesspal_url} mdxType="Link">
    {props.pageContext.frontmatter.myfitnesspal_text}
  </Link>{" "}
  Unternehmen war in den letzten Jahren davon betroffen. Es wurden sensible Informationen wie E-Mailadressen und Passwörter erbeutet,
  die sich nun auf dubiosen Plattformen tümmeln. Doch wie lässt sich so ein Szenario und der daraus entstandene Schaden für den
  Nutzer nachhaltig und glaubwürdig verhindern?
    </p>
    <p>
  Die Antwort ist einfach und effektiv: keine Daten, kein Angriffsvektor. Aus diesem Grund wurde ShapeGanic so entwickelt,
  dass die Nutzung ausschließlich <b>ohne Online-Account</b> erfolgt. Deshalb verlassen deine persönlich erstellen Tracking-Daten niemals
  dein Gerät, um auf potential unsicheren Servern gespeichert zu werden. Doch was passiert, wenn du beispielsweise dein
  Smartphone wechseln und deine Daten auf ein anderes übertragen möchtest?
  Hierfür stellt dir ShapeGanic eine Funktion zum sicheren Exportieren und Importieren deiner Daten bereit.
    </p>
    <h3>{`Backup mit nur einem Klick`}</h3>
    <p>
  In der folgenden Abbildung siehst du die Übersicht der Einstellungen
  und App-übergreifenden Aktionen in ShapeGanic. Mit dem Aktionselement <b>"Backup erstellen"</b> ("Create backup") kannst du
  einfach eine Sicherung deiner App-Daten erstellen. Mit einem Klick auf <b>"Backup einlesen"</b> ("Read in backup") erhälst du die
  Möglichkeit eine zuvor erstellte Sicherung wiederherzustellen.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} mdxType="AppImage" />
    <p>
  Beim Erstellen einer Daten-Sicherung musst du anschließend nur
  noch den Dateinamen bestätigen oder anpassen und den Speicherort verifizieren.
  Das Backup wird ohne merkliche Verzögerung erstellt und du kannst die App
  anschließend wie gewohnt weiter nutzen.
    </p>
    <h2>{`Backup einfach wiederherstellen`}</h2>
    <p>
  Eine Backup Datei kannst du einfach wiederherstellen, indem du im oben gezeigten Menü die
  Option <b>"Backup einlesen"</b> ("Read in backup") anklickst und anschließend die
  entsprechende Backup Datei auswählst. Alternativ kannst du einfach auf die Backup Datei in einer Datei
  Explorer App klicken. Anschließend öffnet sich die ShapeGanic App oder ein Dialog zur Auswahl einer
  geeigenten App, hier musst du <b>ShapeGanic</b> auswählen.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} mdxType="AppImage" />
    <p>
  In der nun eingeblendeten Ansicht kannst du die Datentypen auswählen, welche du importieren möchtest.
  Diese Funktion ist jeweils auf der obigen und unteren Abbildung dargestellt. Praktischerweise stehen bei jedem Datentyp
  die Anzahl der Einträge, welche in der Backup Datei vorhanden sind. Mit einem Klick auf die oberste
  CheckBox kannst du alle Optionen aus- oder abwählen.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} mdxType="AppImage" />
    <p>
  Mit einem Klick auf <b>"Importieren"</b> ("Import") werden die von dir gewählten Datentypen nach einem kurzen Augenblick importiert.
  Nun stehen dir all deine getrackten Einträge wieder zu Verfügung! Und denk am besten regelmäßig daran deine Daten auf diese Weise zu sichern
  und an einem sicheren Ort deiner Wahl zu speichern.
    </p>
    <h2>{`Automatische Sicherung deiner Daten`}</h2>
    <p>
  Auf Android Geräten hast du zusätzlich oder auch alternativ die Möglichkeit die automatische
  Backup Funktion deines Systems mit{" "}
  <Link url={props.pageContext.frontmatter.google_one_url} mdxType="Link">
    {props.pageContext.frontmatter.google_one_text}
  </Link>{" "}
  einzurichten. Hierbei werden regelmäßig die Daten deiner auf dem Gerät installierten Apps
  auf Google Drive gesichert. Installierst du eine App auf einem anderen Gerät,
  welches mit dem gleichen Google Konto eingerichtet wurde, oder
  nachdem du es zuvor auf deinem jetzigen Gerät deinstalliert hast, so werden all deine App Daten
  automatisch wiederhergestellt. Durch diesen Mechanismus werden auch all deine ShapeGanic Einträge wiederhergestellt.
    </p>
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      